/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import axios from 'axios';
import { decryptResponse, encryptPayload } from './CryptoUtil';
import * as XLSX from 'xlsx';
import { APP_URL } from '../App';


const Report = () => {
  
  const [selectedAction, setSelectedAction] = useState('register');
 
  const [wait, setWait] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const[selectedRegistration,setSelectedRegistration]= useState('');
  const [startdate, setStartdate] = useState('');
  const [enddate, setEnddate] = useState('');
  const [items, setItems] = useState([]);
  const [commanditems, setCommandItems] = useState([]);
  const [showCommandItems, setShowCommandItems] = useState(false);



  const handleTabClick = (action) => {
    setSelectedAction(action);
    // setStartdate('')
    // setEnddate('')
    setItems([])
    setCommandItems([])
  };

  const handleSubmit=async (e)=>{
    e.preventDefault();

    setWait(true)

    if(startdate > enddate){
      setWait(false)
      return alert("End date cannot be prior to start date")
    }
   
    let payload="";

    if(selectedAction==='register'){
      setShowTable(false);
      payload= encryptPayload({
        "report":selectedAction,
        "startdate":startdate,
        "enddate":enddate,
        "match":selectedRegistration
      })

      
    }else{
      if(selectedAction==="deenroll"){
        setShowTable(false);
      }else{
        setShowTable(true);
      }
      payload=encryptPayload({
        "report":selectedAction,
        "startdate":startdate,
        "enddate":enddate,       
      })
      
    }
    try{
      const response= await axios.post(APP_URL+'downloadReports.php',
        payload ,
        {headers: {
          'Content-Type': 'application/json',
          'X-API-Key': decryptResponse(JSON.parse(localStorage.getItem("userSession")).data.payload,JSON.parse(localStorage.getItem("userSession")).data.transactionId).token
        
        }})

   
      

      const decryptText = decryptResponse(response.data.payload,response.data.transactionId)
    
      if(decryptText.status==="success"){
        setItems(decryptText.items)
        try{
          setCommandItems(decryptText.commanditems)
          setShowCommandItems(true)
        }catch{
          setShowCommandItems(false)
          setCommandItems([])

        }
      }else{
        alert(decryptText.message)
      }
    }catch(error){
      alert("Something went wrong.Please try again in sometime")
    }
    setWait(false)
    
      
    
  }

  const handleFilter=(e)=>{
    setSelectedRegistration(e.target.value)
  }

  const generateExcel=()=>{
    const workbook = XLSX.utils.book_new();
   

    // Convert JSON to worksheet
    const worksheet = XLSX.utils.json_to_sheet(items);

    // Append the worksheet to the workbook
   if(selectedAction==="deenroll"){
    XLSX.utils.book_append_sheet(workbook, worksheet, "deenroll");
    
    // Generate the Excel file and trigger a download
    const filename = 'deenroll_record'+'-'+startdate+'-'+enddate+'.xlsx'
    XLSX.writeFile(workbook, filename);

   }else{
    XLSX.utils.book_append_sheet(workbook, worksheet, "Registration Status");
    let found = "Matched"
    
   

    if(selectedRegistration==="NO"){
      found= "Unmatched"     

    }else if(selectedRegistration==="ALL"){
      found="All"
    }

    // Generate the Excel file and trigger a download
    const filename = 'Registration_Status_'+found+'-'+startdate+'-'+enddate+'.xlsx'
    XLSX.writeFile(workbook, filename);
    
   } 
   

  }

  const generateCommandsExcel=()=>{
    const workbook = XLSX.utils.book_new();
   

    // Convert JSON to worksheet
    const newCommandItems = commanditems.map(({ id, ...rest }) => rest);

    const worksheet = XLSX.utils.json_to_sheet(newCommandItems);

    // Append the worksheet to the workbook
  
    XLSX.utils.book_append_sheet(workbook, worksheet, selectedAction+"command");
    let found = selectedAction
    
   


    // Generate the Excel file and trigger a download
    const filename = found+'-'+startdate+'-'+enddate+'.xlsx'
    XLSX.writeFile(workbook, filename);
    
   
   

  }


  const generateBulkUploadExcel=()=>{
    const workbook = XLSX.utils.book_new();
   

    // Convert JSON to worksheet
    const worksheet = XLSX.utils.json_to_sheet(items);

    // Append the worksheet to the workbook
  
    XLSX.utils.book_append_sheet(workbook, worksheet, "bulkupload");
    let found = selectedAction
    
   


    // Generate the Excel file and trigger a download
    const filename = found+'-bulkUpload-'+startdate+'-'+enddate+'.xlsx'
    XLSX.writeFile(workbook, filename);
    
   
   

  }
  return (
    <div className='row'>
      <div className="col-md-12 col-xl-12 grid-margin stretch-card">
        <div className="card" role="tablist">
          <div className="card-body">
            <h4 className="card-title">Bulk Upload History Details</h4>
            <ul className="nav nav-pills nav-pills-success" id="pills-tab" >
            <li className="nav-item">
                <button
                  className={`nav-link my-1 ${selectedAction === 'register' ? 'active' : ''}`}
                  id="pills-notify-tab"
                  data-toggle="pill"
                  onClick={() => handleTabClick('register')}
                >
                  Registrations
                </button>
              </li>

              <li className="nav-item">
                <button
                  className={`nav-link my-1 ${selectedAction === 'deenroll' ? 'active' : ''}`}
                  id="pills-release-tab"
                  data-toggle="pill"
                  onClick={() => handleTabClick('deenroll')}
                >
                  De-enrollment/Re-registery Records
                </button>
              </li>




              <li className="nav-item">
                <button
                  className={`nav-link my-1 ${selectedAction === 'lock' ? 'active' : ''}`}
                  id="pills-lock-tab"
                  data-toggle="pill"
                  onClick={() => handleTabClick('lock')}
                >
                  Lock  History
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link my-1 ${selectedAction === 'unlock' ? 'active' : ''}`}
                  id="pills-unlock-tab"
                  data-toggle="pill"
                  onClick={() => handleTabClick('unlock')}
                >
                  Unlock  History
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link my-1 ${selectedAction === 'release' ? 'active' : ''}`}
                  id="pills-release-tab"
                  data-toggle="pill"
                  onClick={() => handleTabClick('release')}
                >
                  Release  History
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link my-1 ${selectedAction === 'notify' ? 'active' : ''}`}
                  id="pills-notify-tab"
                  data-toggle="pill"
                  onClick={() => handleTabClick('notify')}
                >
                  Notify  History
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link my-1 ${selectedAction === 'block' ? 'active' : ''}`}
                  id="pills-notify-tab"
                  data-toggle="pill"
                  onClick={() => handleTabClick('block')}
                >
                  Block  History
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link my-1 ${selectedAction === 'unblock' ? 'active' : ''}`}
                  id="pills-notify-tab"
                  data-toggle="pill"
                  onClick={() => handleTabClick('unblock')}
                >
                  Unblock  History
                </button>
              </li>
            </ul>
            <div className="tab-content mt-4" id="pills-tabContent">
              <div className='row'>
              <div className="col-md-12 grid-margin transparent">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Download reports : {selectedAction}</h4>
              <form className="forms-sample" onSubmit={handleSubmit}>
                <div className='row'>
                  <div className="col-sm-4">
                  <div className="form-group">
                 
                  <div className="col-sm-12">
                  <p>StartDate</p>
                  <input type="date" placeholder="startdate"  onChange={(e)=>setStartdate(e.target.value)} className="form-control   input-sm "  required  />
                  
                   
                  </div>
                </div>

                  </div>
                  <div className="col-sm-4">
                      <div className="form-group">
                    
                      <div className="col-sm-12">
                        <p>EndDate</p>
                      <input type="date"  placeholder="Select End Date"  onChange={(e)=>setEnddate(e.target.value)} className="form-control  input-sm "  required  />
                      </div>
                    </div>
                    
                  </div>

                  {selectedAction === 'register'?
                  <div className='col-sm-3'>
                <div className="form-group">
                  
                  <div className="col-sm-12">
                  <p>Select Registration Filter</p>
                  <select className="form-control  input-sm "  required value={selectedRegistration}
                               onChange={handleFilter}>
                      <option value="">----Choose Registration Filter----</option>
                      <option value="ALL">All</option>
                      <option value="YES">Matched</option>
                      <option value="NO">Unmatched</option>
                     
                    </select>
                
                   
                  </div>
                  </div>
                </div>
                :''}




                </div>

                <div className="submit-buttons">
                  <button type="submit" className="btn btn-success me-2 btn-sm text-white" >
                    Submit
                  </button>
                  <button className="btn btn-danger btn-sm text-white" >
                    Cancel
                  </button>
                </div>

                {wait ?
               
                <p>Please wait while we fetch the data </p>
                  :''
                  }
              </form>

              
              { showTable && items.length>0 ?

              <>

              <br></br>
              <hr></hr>

                <h4> Bulk Upload {selectedAction} History </h4>
                <button onClick={generateBulkUploadExcel} className="btn btn-info btn-sm text-white" >
               Click here to Download Bulk Upload History 
             </button>
             <hr></hr>
             <br></br>
              
                <div className='table-responsive'>
                <table className="table table-striped table-borderless">
                    <thead>
                      <tr>
                        <th>S.No.</th>
                        <th>Date and Time</th>
                        <th>File Name</th>
                        <th>Original File Name</th>
                        <th>Status of File</th>
                        <th>Last Imei</th>
                        <th>Remarks</th>
                        <th>Download</th>
                       
                      </tr>
                    </thead>
                    <tbody>
                      {items.map((item, index) => (
                        <tr key={item.id}>
                          <td>{index + 1}</td>
                          <td>{item.date_and_time}</td>
                          <td>{item.file}</td>
                          <td>{item.oldfile}</td>
                          <td>{item.isComplete}</td>
                          <td>{item.lastimei}</td>
                          <td>{item.remarks}</td>
                          <td><a href={'../webapi/'+selectedAction+'/'+item.file}> Download File</a></td>
                         
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  </div>
              </>
                
              
              

              :<></>
              
            }

{ showCommandItems && commanditems.length>0 ?

            <>
            <br></br>
            <hr></hr>

             <h4> Executed Commands {selectedAction} History : S- Single , A- API , B - Bulk</h4>
             <button onClick={generateCommandsExcel} className="btn btn-info btn-sm text-white" >
               Click here to Download Executed Command History
             </button>
             <hr></hr>
             <br></br>
              <div className='table-responsive'>
              <table className="table table-striped table-borderless">
                  <thead>

                  
                    <tr>
                      <th>S.No.</th>
                     
                      <th>IMEI1</th>
                      <th>Transaction ID</th>
                      <th>Interface </th>
                      <th>Command </th>
                      <th>Status </th>
                      <th>Date and Time</th>
                      <th>Triggered By </th>
                      
                      
                     
                    </tr>
                  </thead>
                  <tbody>
                    {commanditems.map((item, index) => (
                      <tr key={item.id +item.transactionId}>
                        <td>{index + 1}</td>
                       
                        <td>{item.imei1}</td>
                        <td>{item.transactionId}</td>
                        <td>{item.interface}</td>
                        <td>{item.command}</td>
                        <td>{item.status}</td>
                        <td>{item.date_and_time}</td>
                        <td>{item.triggered_by}</td>
                        
                        
                       
                      </tr>
                    ))}
                  </tbody>
                </table>
                </div>
              
              </>
            
            

            :<></>
            
          }


            {selectedAction ==='register' && items.length>0 ?
            <>
            <br></br>
            <hr></hr>
             
             <button onClick={generateExcel} className="btn btn-info btn-sm text-white" >
               Click here to Download file
             </button>
             <hr></hr>
            
            </>
            
            
             :         
          ''
              
              
            }


            
 

            {selectedAction ==='deenroll' && items.length>0 ?
            <>
            <br></br>
            <hr></hr>
             
             <button onClick={generateExcel} className="btn btn-info btn-sm text-white" >
               Click here to Download De-enroll/Re-register Report
             </button>
             <hr></hr>
            
            </>
            
            
             :         
          ''
              
              
            }




            </div>
          </div>
        </div>

              </div>
        
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
Report.displayName = "Report"
export default Report;
