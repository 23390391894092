import React from 'react';

import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './components/login.js';

import Home from './components/home.js';
import Device_detail from './components/device_detail.js';
import Notify from './components/notify.js';
import Release from './components/release.js';
import Template from './components/template';


import Lock_device from './components/lock_device.js';
import Unlock_device from './components/unlock_device.js';
import Report from './components/Report.js';
import Logout from './components/logout.js';
import BlockApp from './components/blockapp.js';
import UnblockApp from './components/unblockapp.js';
import NotAuthorised from './components/NotAuthorised.js';



import User_Management from './components/User_Management.js';
import ResetPassword from './components/ResetPassword.js';
import ForgotPassword from './components/ForgotPassword.js';
import KeyManagement from './components/KeyManagement.js';
import FormattedDevice from './components/FormattedDevice.js';
import { decryptResponse } from './components/CryptoUtil.js';
import AuditReport from './components/AuditReport.js';
import AuditReportByImei from './components/AuditReportByImei.js';

export const BATCH_SIZE =30

const production = false;
export const withCredentials = false;
export const BASE_URL = production?"https://www.screenbroshdb.in":"https://www.screenbros.co"
export const APP_URL =BASE_URL+"/webapi/";

const PrivateRoute = ({ element: Component }) => {
  let isAuthenticated=false;
  let isPermission = false;
  let isAdmin = false;
  if(localStorage.getItem('userSession')){
    const permissions = JSON.parse(decryptResponse(JSON.parse(localStorage.getItem("userSession")).data.payload,JSON.parse(localStorage.getItem("userSession")).data.transactionId).permissions);
    console.log(permissions)
    const searchPermission = Component.displayName;
    console.log(searchPermission)

    isAdmin= decryptResponse(JSON.parse(localStorage.getItem("userSession")).data.payload,JSON.parse(localStorage.getItem("userSession")).data.transactionId).userlevel === 'ADMIN';
    if(isAdmin){
      isPermission =true;

    }else{

     if(searchPermission ==="Home" || searchPermission==="Logout"){
      isPermission = true;
     }else{
      isPermission = permissions.find(permission => permission.permission_name === searchPermission);
      console.log(isPermission)
     }
    }

    isAuthenticated = decryptResponse(JSON.parse(localStorage.getItem("userSession")).data.payload,JSON.parse(localStorage.getItem("userSession")).data.transactionId).loggedin === 'loginValidated';
  }
  return isAuthenticated ? isPermission ?<Component/>:<NotAuthorised/>:<Navigate to="/login" />;
};

const AdminManagementRoute = ({ element: Component }) => {
  let isAdmin=false;
  if(localStorage.getItem('userSession')){
    isAdmin= decryptResponse(JSON.parse(localStorage.getItem("userSession")).data.payload,JSON.parse(localStorage.getItem("userSession")).data.transactionId).userlevel === 'ADMIN';
    
  }
  
  return isAdmin ? <Component /> : <Navigate to="/login" />;
};

function App() {


  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/forgotPassword" element={<ForgotPassword/>} />
        <Route path="/resetPassword/:token" element={<ResetPassword/>} />
          <Route path="/" element={<Template />}>
          <Route index element={<PrivateRoute element={Home} />} />
          <Route path="lockDevice" element={<PrivateRoute element={Lock_device} />} />
          <Route path="blockApp" element={<PrivateRoute element={BlockApp} />} />
          <Route path="unblockapp" element={<PrivateRoute element={UnblockApp} />} />
          <Route path="unlockDevice" element={<PrivateRoute element={Unlock_device} />} />
          <Route path="release" element={<PrivateRoute element={Release} />} />
          <Route path="deviceDetails" element={<PrivateRoute element={Device_detail} />} />
          <Route path="formattedDevice" element={<PrivateRoute element={FormattedDevice} />} />
          <Route path="notify" element={<PrivateRoute element={Notify} />} />
          <Route path="userManagement" element={<PrivateRoute element={User_Management} />} />
          {/* <Route path="report" element={<PrivateRoute element={Report} />} /> */}
          <Route path="reports" element={<PrivateRoute element={Report} />} />
          <Route path="auditReport" element={<PrivateRoute element={AuditReport} />} />
          <Route path="auditReportbyimei" element={<PrivateRoute element={AuditReportByImei} />} />
                
         
          <Route path="logout" element={<PrivateRoute element={Logout} />} />
        </Route>
      </Routes>
    </Router>
  );
}
App.displayName = "App"
export default App;
