import axios from 'axios';
// eslint-disable-next-line no-unused-vars
import React, { useState ,useRef} from 'react';
import { encryptPayload,decryptResponse } from './CryptoUtil';
import { APP_URL, withCredentials } from '../App';
import * as XLSX from 'xlsx';

const AuditReportByImei = () => {

  const [regitems, setRegItems] = useState([]);
  const [releaseitems, setReleaseItems] = useState([]);
  const [cmditems, setCmdItems] = useState([]);
  const [deenrollitems, setdeenrollItems] = useState([]);
  const [imei1,setImei1]=useState(null)

  const printRef = useRef();

  const handlePrint = () => {

  
    const printContent = printRef.current;
    const printWindow = window.open("", "_blank", "width=800,height=800");
    printWindow.document.write(`<html><head><title>Audit Report By Imei </title>
       <style>td{padding:10px}</style></head><body>`);
    printWindow.document.write(printContent.innerHTML);
    printWindow.document.write("</body></html>");
    printWindow.document.close();
    printWindow.print();
  };

  
 

 

  const handleSearch = (e) => {
    e.preventDefault();

    
  };
  const searchImei1=async (e)=>{
    const imei1 = e.target.value

    
      const data= encryptPayload({ "imei1":imei1 })
     
      try{
      const response = await axios.post(APP_URL+'auditReportByImei.php',
        data,
        {
          withCredentials: withCredentials,
          headers: {
          'Content-Type': 'application/json',
          'X-API-Key': decryptResponse(JSON.parse(localStorage.getItem("userSession")).data.payload,JSON.parse(localStorage.getItem("userSession")).data.transactionId).token
        
        }})
     
      const decryptText = decryptResponse(response.data.payload,response.data.transactionId)
      setRegItems(decryptText.registration);
      setReleaseItems(decryptText.release);
      setCmdItems(decryptText.commands);
      setdeenrollItems(decryptText.deenroll)
      setImei1(imei1)
      }catch(error){
        alert("Cannot fetch the data now, please try again")
      }
    

  }


  const handleDownload=()=>{
    const workbook = XLSX.utils.book_new();
   

    // Convert JSON to worksheet
    const worksheetregitems = XLSX.utils.json_to_sheet(regitems);
    const worksheetreleaseitems = XLSX.utils.json_to_sheet(releaseitems);
    const worksheetcommanditems = XLSX.utils.json_to_sheet(cmditems);
    const worksheetdenrollitems = XLSX.utils.json_to_sheet(deenrollitems);

    // Append the worksheet to the workbook
  
    XLSX.utils.book_append_sheet(workbook, worksheetregitems, "registration");
    XLSX.utils.book_append_sheet(workbook, worksheetreleaseitems, "release");
    XLSX.utils.book_append_sheet(workbook, worksheetcommanditems, "commands");
    XLSX.utils.book_append_sheet(workbook, worksheetdenrollitems, "de-enroll");
    
    
    
    
   


    // Generate the Excel file and trigger a download
    const filename = 'AuditReport_Sbhdb_imei-'+imei1+'.xlsx'
    XLSX.writeFile(workbook, filename);
    
   
   

  }


  return (
    <div >
      <div className="row" >
      <div className="col-md-12 grid-margin transparent">
          <div className="row">
            <div className="col-md-5 mb-4 transparent">
              <h2 className="font-weight-bold">Audit Report By IMEI </h2>
            </div>
           
          </div>
        </div>
        <div className="col-md-12 grid-margin transparent">
          <div className="form-group">
            <div className="input-group">
              
              <input
                type="text"
                className="form-control form-control-sm pe-5"
                onChange={searchImei1}
                placeholder='Enter Imei of device'
                aria-label="Search by Imei"
              />
            
              <button
                className="btn btn-sm btn-primary position-absolute end-0 top-0 bottom-0 rounded-0"
                type="button"
                id="searchButton"
                onClick={handleSearch}
              >
                <i className="icon-search"></i>
              </button>
            </div>
          </div>
        </div>
        <div ref={printRef}>

        {regitems.length > 0 && (
          <div className="col-md-12 grid-margin transparent">
            <div className="card">
           
               
              <div className="card-body">
              <h5 className="card-title">Registration Details Imei - {imei1}</h5>
                <div className="table-responsive">
                  <table className="table table-striped " border={1}>
                    <thead>
                      <tr>
                        <th>S.No.</th>
                        <th>IMEI 1</th>
                        <th>IMEI 2</th>
                        <th>Brand</th>
                        <th>Model</th>
                        <th>Command</th>
                        <th>Created At</th>
                      </tr>
                    </thead>
                    <tbody>
                      {regitems.map((item, index) => (
                        <tr key={item.imei1+'reg'}>
                          <td>{index + 1}</td>
                          <td>{item.imei1}</td>
                          <td>{item.imei2}</td>
                          <td>{item.brand}</td>
                          <td>{item.model}</td>
                          <td>{item.command}</td>
                          <td>{item.createdat}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>



        )}
            {releaseitems.length > 0 && (
          <div className="col-md-12 grid-margin transparent">
            <div className="card">
           
               
              <div className="card-body">
              <h5 className="card-title">Released Detail for Imei - {imei1}</h5>
                <div className="table-responsive">
                  <table className="table table-striped " border={1}>
                    <thead>
                      <tr>
                        <th>S.No.</th>
                        <th>IMEI 1</th>
                        <th>IMEI 2</th>
                        <th>Brand</th>
                        <th>Model</th>
                       
                        <th>Created At</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {releaseitems.map((item, index) => (
                        <tr key={item.imei1+'rel'}>
                          <td>{index + 1}</td>
                          <td>{item.imei1}</td>
                          <td>{item.imei2}</td>
                          <td>{item.brand}</td>
                          <td>{item.model}</td>
                          
                          <td>{item.createdat}</td>
                          <td>RELEASED</td>
                          
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>



        )}

{deenrollitems.length > 0 && (
          <div className="col-md-12 grid-margin transparent">
            <div className="card">
           
               
              <div className="card-body">
              <h5 className="card-title">De-enroll and Re-registration Details  {imei1}</h5>
                <div className="table-responsive">
                  <table className="table table-striped " border={1}>
                    <thead>
                      <tr>
                        <th>S.No.</th>
                        <th>IMEI 1</th>
                        <th>IMEI 2</th>
                        <th>Action</th>
                        <th>Date</th> 
                       
                      </tr>
                    </thead>
                    <tbody>
                      {deenrollitems.map((item, index) => (
                        <tr key={item.createdat}>
                          <td>{index + 1}</td>
                          <td>{item.imei1}</td>
                          <td>{item.imei2}</td>
                          <td>{item.action}</td>
                          <td>{item.createdat}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>



        )}

{cmditems.length > 0 && (
          <div className="col-md-12 grid-margin transparent">
            <div className="card">
              <div className="card-body">
              <h5 className="card-title">Executed Commands on  {setImei1}</h5>
                <div className="table-responsive">
                  <table className="table table-striped " border={1}>
                    <thead>
                      <tr>
                        <th>S.No.</th>
                        <th>IMEI 1</th>
                        
                        <th>Commands</th>
                        <th>Transaction Id</th>
                        <th>Command Type</th>
                        <th>Command Date</th>
                        <th>Command Status</th>
                        
                      </tr>
                    </thead>
                    <tbody>
                      {cmditems.map((item, index) => (
                        <tr key={item.createdat}>
                          <td>{index + 1}</td>
                          <td>{item.imei1}</td>
                         
                          <td>{item.command}</td>
                          <td>{item.transactionId}</td>
                          <td>{item.commandType}</td>
                          <td>{item.createdat}</td>
                          <td>{item.status}</td>
                         
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
)}
</div>
        {regitems.length === 0  && (
          <div className="col-md-12 grid-margin transparent">
            <p>No Registration record found. Device might be released or not registered. Please check released record.</p>
          </div>
        )}
      </div>

      <div className="row mb-6" style={{marginRight:"40px",float:"right"}}>
      <button className="col-12 btn btn-block btn-info btn-sm text-white" onClick={handleDownload}>Download Report</button> 
     
     </div>
     <div className="row mb-6" style={{marginRight:"40px",float:"right"}}>
      
      <button className="col-12 btn btn-block btn-success btn-sm text-white" onClick={handlePrint}>Print Report</button>
     </div>
    </div>
  );
};
AuditReportByImei.displayName = 'AuditReportByImei'; // Set the display name manually
export default AuditReportByImei;

