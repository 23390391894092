import React, { useState } from 'react';
import axios from 'axios';
import { APP_URL } from '../App';
import { encryptPayload,decryptResponse } from './CryptoUtil';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email) {
      setMessage("Please enter a valid email address.");
      return;
    }

    try {
        const data= encryptPayload({ "email":email})
       
        const response = await axios.post(APP_URL+'forgotPassword.php', 
            data ,
               
            {   
                headers:{
                'Content-Type':'Application/json'
                }
            }
        );
        const decryptText = decryptResponse(response.data.payload,response.data.transactionId)

        if (decryptText.status === 'success') {      
       
       
          localStorage.setItem('resetToken', decryptText.token);
        

        setMessage('Password reset link sent to your email.');
      } else {
        setMessage('Error: ' + decryptText.message);
      }
    } catch (error) {
      
      
      setMessage('An error occurred. Please try again.'+error);
    }
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="auth-form-light text-left py-5 px-4 px-sm-5">
            <h4>Forgot Password</h4>
            <p>Enter your account's verified email address, and we will send you a password reset link.</p>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  type="email"
                  className="form-control"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                  required
                />
              </div>
              <button type="submit" className="btn btn-primary btn-block">Send Password Reset Email</button>
            </form>
            {message && <p className="mt-3">{message}</p>}
          </div>
        </div>
      </div>
    </div>
  );
};
ForgotPassword.displayName = "ForgotPassword"
export default ForgotPassword;
