import CryptoJS from 'crypto-js';



// Function to encrypt the payload

const generateRandomIV = () => {
    // Generate a random 16-byte (128-bit) IV
    const iv = CryptoJS.lib.WordArray.random(16);
  
    // Convert the IV to a hexadecimal string
    const ivHex = iv.toString(CryptoJS.enc.Hex);
  
    return ivHex;
  };

export const  encryptPayload=(payload)=> {
    const secretKey = 'b1a2f20a31e6f678d9b4e3f92dc8c7b3a6b8d18f307b482c290c412f7db935e5';
    const iv = generateRandomIV();
    const ciphertext = CryptoJS.AES.encrypt(
        JSON.stringify(payload),
        CryptoJS.enc.Hex.parse(secretKey),
        {
            iv: CryptoJS.enc.Hex.parse(iv),
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        }
    )
    return {'payload':ciphertext.ciphertext.toString(CryptoJS.enc.Hex),'transactionId':iv};
}

export const  encryptPayloadLogin=(payload)=> {
    const secretKey = 'b1a2f20a31e6f678d9b4e3f92dc8c7b3a6b8d18f307b482c290c412f7db935e5';
    const iv = generateRandomIV();
    const ciphertext = CryptoJS.AES.encrypt(
        JSON.stringify(payload),
        CryptoJS.enc.Hex.parse(secretKey),
        {
            iv: CryptoJS.enc.Hex.parse(iv),
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        }
    )
    return {'payload':ciphertext.ciphertext.toString(CryptoJS.enc.Hex),'transactionId':iv};
}

// Function to decrypt the response
export const decryptResponse=(ciphertext,iv) =>{
    const secretKey = 'b1a2f20a31e6f678d9b4e3f92dc8c7b3a6b8d18f307b482c290c412f7db935e5';
   
    const bytes = CryptoJS.AES.decrypt(
        {ciphertext:CryptoJS.enc.Hex.parse(ciphertext)},
        CryptoJS.enc.Hex.parse(secretKey),
        {
            iv: CryptoJS.enc.Hex.parse(iv),
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        }
    );
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
}
export const decryptResponseLogin=(ciphertext,iv) =>{
    const secretKey = 'b1a2f20a31e6f678d9b4e3f92dc8c7b3a6b8d18f307b482c290c412f7db935e5';
   
    const bytes = CryptoJS.AES.decrypt(
        {ciphertext:CryptoJS.enc.Hex.parse(ciphertext)},
        CryptoJS.enc.Hex.parse(secretKey),
        {
            iv: CryptoJS.enc.Hex.parse(iv),
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        }
    );
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
}