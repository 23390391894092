/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import axios from 'axios';
import { decryptResponse, encryptPayload } from './CryptoUtil';
import * as XLSX from 'xlsx';
import { APP_URL } from '../App';


const NotAuthorised = () => {
  
  
   


   
  return (
    <div className='row'>
        <div className='col-md-12'>
            <h3> You are not authorised to use this feature.Contact Your Admin for support</h3>
        </div>
     




        
    </div>
  );
};
NotAuthorised.displayName = "NotAuthorised"
export default NotAuthorised;
