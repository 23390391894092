import React, { useState, useEffect } from 'react';

import { APP_URL, withCredentials } from '../App';
import axios from 'axios';
import { decryptResponse } from './CryptoUtil';
//import Modal from 'react-modal'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { encryptPayload } from './CryptoUtil';
import { Form, Row, Col, Alert,  OverlayTrigger, Tooltip } from "react-bootstrap";






const User_Management = () => {
    const [users, setUsers] = useState([]);
    const [permissionsList, setPermissionsList] = useState([]);  
   
    const [editUser, setEditUser] = useState(null);
    const [modalError, setModalError] = useState("");
    const [modalErrorAdd, setModalErrorAdd] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [show, setShow] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
   


    const initialFormData = {
       
        email: "",
        phone: "",
        id:"",    
        permissions: [],
    };
    const [formData, setFormData] = useState(initialFormData);


    const initialFormDataAdd = {
       
        email: "",
        phone: "",
        permissions: [],
    };
    const [formDataAdd, setFormDataAdd] = useState(initialFormDataAdd);

   
   
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

//   const handleEditClose = () => setShowEdit(false);
//   const handleEditShow = () => setShowEdit(true);

    // Fetch all users from the backend on component mount
    useEffect(() => {
        getPermissionDetails();
        getUserDetails();
    }, []);

    const getUserDetails = async ()=>{
        try{
            const response = await axios.get(APP_URL+'getUsers.php',
             
              {
                withCredentials: withCredentials,
                headers: {
                'Content-Type': 'application/json',
                'X-API-Key': decryptResponse(JSON.parse(localStorage.getItem("userSession")).data.payload,JSON.parse(localStorage.getItem("userSession")).data.transactionId).token
        
              }})
           
            const decryptText = decryptResponse(response.data.payload,response.data.transactionId)
            setUsers(decryptText.items);
            }catch(error){
              alert("Cannot fetch the data now, please try again")
            }
    }

    const getPermissionDetails = async ()=>{
        try{
            const response = await axios.get(APP_URL+'getPermissions.php',
             
              {
                withCredentials: withCredentials,
                headers: {
                'Content-Type': 'application/json',
                'X-API-Key': decryptResponse(JSON.parse(localStorage.getItem("userSession")).data.payload,JSON.parse(localStorage.getItem("userSession")).data.transactionId).token
        
              }})
           
            const decryptText = decryptResponse(response.data.payload,response.data.transactionId)
            setPermissionsList(decryptText.permissions);
            }catch(error){
              alert("Cannot fetch the data now, please try again")
            }
    }


    const handlePermissionChange = (permissionId) => {
        setFormData((prev) => ({
            ...prev,
            permissions: prev.permissions.includes(permissionId)
                ? prev.permissions.filter((id) => id !== permissionId)
                : [...prev.permissions, permissionId],
        }));
    };


    const handlePermissionChangeAdd = (permissionId) => {
        

        setFormDataAdd((prev) => ({
            ...prev,
            permissions: prev.permissions.includes(permissionId)
                ? prev.permissions.filter((id) => id !== permissionId)
                : [...prev.permissions, permissionId],
        }));
       
    };

    const handleInputChangeAdd = (e) => {
        setFormDataAdd({ ...formDataAdd, [e.target.name]: e.target.value });
    };



    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleEditUser = async (user) => {
        setShowEdit(true);
        try {
           
            
            //const userPermissionIds = user.permissions_id.map(perm => perm.permissions_id.toString()); // Convert to string for matching
            let userPermissionIds
            if(user.permissions ==="No Permission"){
                userPermissionIds=[];

            }else{
             userPermissionIds = user.permissions_id.toString().split(",").map(Number); // Convert to string for matching
            }
            
            

            
            
            
    
            setEditUser(user);
            setFormData({
                
                email: user.email,
                id:user.id,
                phone: user.phone,
                permissions: userPermissionIds
            });
           
           
    
            
        } catch (error) {
            console.error("Error fetching user permissions:", error);
        }
    };

    


    // Toggle login status with confirmation
    const toggleLoginStatus = async (email) => {
        const confirmToggle = window.confirm(`Are you sure you want to Logout this user?` + email);
        if (confirmToggle) {
            axios.post(APP_URL+"updateLoginStatus.php", { "email":email}, {
                withCredentials: withCredentials,
                headers: {
                'Content-Type': 'application/json',
                'X-API-Key': decryptResponse(JSON.parse(localStorage.getItem("userSession")).data.payload,JSON.parse(localStorage.getItem("userSession")).data.transactionId).token
        
              }})
            .then(response => {

               

               setUsers(users.map(user =>

                user.email === email ? { ...user, loginstatus: "LOGOUT" } : user
               
              ));
            })
            .catch(error => {
              alert(error)
            });
            
        }
    };
    

    // Toggle user status with confirmation
    const toggleUserStatus = async (user,email) => {
        const confirmToggle = window.confirm(`Are you sure you want to ${user.userstatus==="ACTIVE" ? 'disable' : 'enable'} this user? `+email);
        if (confirmToggle) {
            let userstatus ="ACTIVE";
            if(user.userstatus==="ACTIVE"){
                userstatus= "INACTIVE"

            }
            axios.post(APP_URL+"updateUserStatus.php", { "email":email,"status":userstatus}, {
                withCredentials: withCredentials,
                headers: {
                'Content-Type': 'application/json',
                'X-API-Key': decryptResponse(JSON.parse(localStorage.getItem("userSession")).data.payload,JSON.parse(localStorage.getItem("userSession")).data.transactionId).token
        
              }})
            .then(response => {

               

               setUsers(users.map(user =>

                user.email === email ? { ...user, userstatus: userstatus } : user
               
              ));
            })
            .catch(error => {
              alert(error)
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if ( !formData.email || (!editUser)) {
            setModalError("Please fill all required fields.");
            return;
        }

        const allowedDomains = ["@hdbfs.com", "@screenbros.in"];
        if (!allowedDomains.some(domain => formData.email.endsWith(domain))) {
            setModalError("Invalid email domain.");
            return;
        }

        try {
           
                //  Ensure permissions are always sent as an array
                const payload = { 
                    id: editUser ? editUser.id : undefined, // Ensure ID is sent when editing
                    ...formData, 
                    permissions: formData.permissions.map(Number) // Convert permissions to numbers
                };


                const data= encryptPayload(payload)
              




            const response = await axios.post(APP_URL+'updateUser.php', data,
                    {
                      withCredentials: withCredentials,
                      headers: {
                      'Content-Type': 'application/json',
                      'X-API-Key': decryptResponse(JSON.parse(localStorage.getItem("userSession")).data.payload,JSON.parse(localStorage.getItem("userSession")).data.transactionId).token
                    
                    }})

            const decryptText = decryptResponse(response.data.payload,response.data.transactionId)

            if (decryptText.status === 'success') {
          
             
            
                setSuccessMessage("User updated successfully!");
                alert("User updated successfully");
                setModalError("");
                setShowEdit(false);
                setEditUser(null);
                setFormData(initialFormData);
                getUserDetails();
            } else {
                setModalError(decryptText.message || "Error updating user.");
            }
        } catch (error) {
            setModalError(error.response?.data?.message || "Error saving user. Please try again.");
        }
    };





   

    // Handle Add User Form Submission
    const handleAddUserSubmit = async (e) => {
        e.preventDefault();
      

      
        const allowedDomains = ["@hdbfs.com", "@screenbros.in"];
        if (!allowedDomains.some(domain => formDataAdd.email.endsWith(domain))) {
            setModalErrorAdd("Invalid email domain.");
            return;
        }
        const phoneRegex = /^[0-9]{10}$/; // Matches exactly 10 digits
        if(!phoneRegex.test(formDataAdd.phone)){
            setModalErrorAdd("Enter a valid 10 digits number.");           
            return 

        }

        try {
           
                //  Ensure permissions are always sent as an array
                const payload = { 
                    
                    ...formDataAdd, 
                    permissions: formDataAdd.permissions.map(Number) // Convert permissions to numbers
                };


                const data= encryptPayload(payload)
              




            const response = await axios.post(APP_URL+'addUser.php', data,
                    {
                      withCredentials: withCredentials,
                      headers: {
                      'Content-Type': 'application/json',
                      'X-API-Key': decryptResponse(JSON.parse(localStorage.getItem("userSession")).data.payload,JSON.parse(localStorage.getItem("userSession")).data.transactionId).token
                    
                    }})
            const decryptText = decryptResponse(response.data.payload,response.data.transactionId)

            if (decryptText.status === 'success') {
          
             
            
               
                alert("User Added successfully");
                setModalErrorAdd("");               
                setFormDataAdd(initialFormDataAdd);
                setShow(false)
                getUserDetails();
            } else {
                setModalErrorAdd(decryptText.message || "Error updating user.");
            }
        
       

       
            
     
          } catch (error) {
            alert("Please try after sometime")
          }

       
    };


    return (
        <div>
                <>
                <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}>
                    <Modal.Header closeButton>
                    <Modal.Title>Add User</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    {modalErrorAdd && <Alert variant="danger">{modalErrorAdd}</Alert>}
     
                    <form onSubmit={handleAddUserSubmit}>
                       
                        <div className="form-group">
                            <label>Email</label>
                            <input type="email" name="email" onChange={handleInputChangeAdd} className="form-control" required />
                        </div>
                        <div className="form-group">
                            <label>Phone</label>
                            <input type="text" name="phone" onChange={handleInputChangeAdd} className="form-control" required />
                        </div>
                        <div className="form-group">
                            <label>Password</label>
                            <input type="password" name="password" onChange={handleInputChangeAdd} className="form-control" required />
                        </div>
                       
                             <div className="form-group">
                    <label>Permission</label>
                    {permissionsList && permissionsList.length > 0 ? (
                        permissionsList.map((perm) => (
                            <div key={perm.id}>
                                <input
                                    
                                    type="checkbox"
                                    onChange={() => handlePermissionChangeAdd(perm.id)}
                                    id={`permission-${perm.id}`} // Unique ID for each checkbox
                                />
                                <label htmlFor={`permission-${perm.id}`} style={{ marginLeft: "10px" }}>
                                    {perm.permission_name} {/* Display permission name */}
                                </label>
                            </div>
                        ))
                    ) : (
                        <p>No permissions found.</p>
                    )}
                </div>
                        <button type="submit" className="btn btn-primary">Add User</button>
                    </form>
             
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        Close
                    </Button>
                    
                    </Modal.Footer>
                </Modal>
                </>


                <>
                <Modal show={showEdit} onHide={() => { setShowEdit(false); setEditUser(null); }}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit User </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {modalError && <Alert variant="danger">{modalError}</Alert>}
                    <Form onSubmit={handleSubmit}>
                        <Row>
                            
                            <Col md={6}><Form.Group><Form.Label>Email</Form.Label><Form.Control type="email" name="email" value={formData.email} onChange={handleInputChange} required readOnly /></Form.Group></Col>
                        
                            <Col md={6}><Form.Group><Form.Label>Phone</Form.Label><Form.Control type="text" name="phone" value={formData.phone} onChange={handleInputChange} required /></Form.Group></Col>
                            
                            
                        </Row>
                        <Row>                      
                            <Col md={12}><Form.Group><Form.Label>Permissions</Form.Label>
                                {permissionsList.map((perm) => (
                                    <Form.Check key={perm.id} type="checkbox"
                                     label={perm.permission_name}
                                        checked={formData.permissions.includes(perm.id)}
                                        onChange={() => handlePermissionChange(perm.id)}
                                        inline />
                                ))}
                            </Form.Group></Col>
                        </Row>
                        <Button variant="primary" type="submit" className="mt-3">Update User</Button>
                    </Form>
                </Modal.Body>
            </Modal>
                </>


               
            




            <div className="row mb-2" style={{marginBottom:"40px"}}>
                <h5 className="col-9">All User Details</h5>
                <button className="col-3 btn btn-block btn-primary btn-sm" onClick={handleShow}>Add User</button>
            </div>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table table-striped table-borderless">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Phone</th>
                                            <th>Permission</th>
                                            <th>Login Status</th>
                                            <th>User Status</th>
                                            <th>Actions</th>
                                           
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {users.map((user) => (
                                            <tr key={user.id}>
                                                <td>{user.name}</td>
                                                <td>{user.email}</td>
                                                <td>{user.phone}</td>
                                                <td>
   <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip>{user.permissions || "No Permissions"}</Tooltip>}
                                >
                                    <Button variant="info" size="sm">View</Button>
                                </OverlayTrigger>
</td>
                                                <td>
                                                    <p className={`text-${user.loginstatus ==="LOGIN" ? 'success' : 'danger'} `} onClick={() => toggleLoginStatus(user.email)}>
                                                        {user.loginstatus} {user.loginstatus ==="LOGOUT"?<></>: 
                                                        <span style={{marginLeft:"10px"}}>

                                                         <img src='/assets/images/log-out.svg'  alt="Logout" title='logout User' />
                                                         </span>
                                                        }
                                                        
                                                    </p>
                                                </td>
                                                <td>
                                                    <p
                                                        
                                                        className={`text-${user.userstatus==="ACTIVE" ? 'primary' : 'secondary'} `}
                                                       
                                                    >
                                                        {user.userstatus}
                                                        <span style={{marginLeft:"10px"}}>
                                                        {user.userstatus==="ACTIVE" ?
                                                            <img  onClick={() => toggleUserStatus(user,user.email)} src='/assets/images/user_x.svg' height="16px" width="16px" alt="Click to Disable User" title='Disable User' />:
                                                            <img  onClick={() => toggleUserStatus(user,user.email)} src='/assets/images/user_check.svg' height="16px" width="16px" alt="Click to Enable User" title='Enable User' />
                                                        }
                                                       
                                                        </span>

                                                        
                                                    </p>
                                                </td>
                                                <td>
                                {/* <Button variant="warning" size="sm" onClick={() => { setEditUser(user); setFormData(user); setShow(true); }}>Edit</Button> */}
                                <Button variant="warning" size="sm" 
                                
                                
                                onClick={() => { handleEditUser(user)}}>Edit</Button>
                            </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

           
            


            
        </div>
    );
};


User_Management.displayName = "User_Management"
export default User_Management;