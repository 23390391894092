/* eslint-disable no-unused-vars */
import axios from 'axios';
import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { APP_URL } from '../App';
import Cookies from 'js-cookie';
import { decryptResponse } from './CryptoUtil';



const Logout = () => {
    const navigate = useNavigate();


  useEffect( () => {
   
    Cookies.remove('sessionToken'); 
    const systemLogout= async()=>{
      try{
        const response =  axios.post(APP_URL+"logout.php",{},
  
         {headers: {
           'Content-Type': 'application/json',
           'X-API-Key': decryptResponse(JSON.parse(localStorage.getItem("userSession")).data.payload,JSON.parse(localStorage.getItem("userSession")).data.transactionId).token
        
         }})
       
  
         if(response.data.status === "success"){
           localStorage.removeItem('userSession');
                   
           navigate("/login");
         }else{
           alert(response.data.message);
         }
     }catch(error){
      localStorage.removeItem('userSession');          
      navigate("/login");
  
     }
    }
    systemLogout()

   

    
  }, [navigate]);



  return (
    <>
    Please wait loggin out...
    </>
  );
};
Logout.displayName = "Logout"
export default Logout;